import React from 'react';
import { withRouter } from 'react-router-dom';

import imgUrl from '../static/img/404.jpeg';
import Button from '@material-ui/core/Button';

class UrlNone extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.back = this.back.bind(this);
	}
	render() {
		const mystyle = {
			width: '50%',
			margin: 'auto',
			display: 'inherit',
			position: 'absolute',
			top: '0',
			bottom: '0',
			left: '0',
			right: '0',
			textAlign: 'center'
		};
		const imgStyle = {
			width: '100%',
			marginTop: '3rem',
			marginBottom: '3rem'
		};
		return (
			<div style={mystyle}>
				<img style={imgStyle} src={imgUrl} />
				<Button onClick={this.back} color="primary" variant="contained">
					主页
				</Button>
			</div>
		);
	}
	componentDidMount() {}
	back() {
		this.props.history.replace("/phone/home")
	}
}
export default withRouter(UrlNone);
