import React from 'react';

import store from "../store/index";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
export default function SimpleBackdrop() {
	const [open, setOpen] = React.useState(false);
	store.subscribe(()=>{
		console.log();
		let newStore = store.getState();
		setOpen(newStore.LOADING);
	})
	const handleClose = () => {
		setOpen(false);
	};
	const handleToggle = () => {
		setOpen(!open);
	};
	const style = {
		zIndex:"1000"
	}
	return (
		<div>
			<Backdrop style={style} open={open} onClick={handleClose}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
