import {CHANGE_LOADING, CHANGE_USER, CHANGE_THEME, CHANGE_PERSONLIST, CHANGE_EVENTMESS, CHANGE_SELECT_OPTIONS } from './actionTypes';
export const changeLoading = (bol) => ({
	type: CHANGE_LOADING,
	loading: bol
});


export const changeUserAction = (value) => ({
	type: CHANGE_USER,
	mess: value
});
export const changeTheme = (value) => ({
	type: CHANGE_THEME,
	theme: value
});
export const changePersonlist = (value) => ({
	type: CHANGE_PERSONLIST,
	changePerson: value
});
export const changeEventMess = (mess) => ({
	type: CHANGE_EVENTMESS,
	eventMess: mess
});
export const changeSelectOptions = (mess) => ({
	type: CHANGE_SELECT_OPTIONS,
	optionsMess: mess
});
