import Fly from './index.js';
export const FlyObj = Fly;
export const getLoginInfo = (token) => {
	Fly.config.baseURL = 'https://open.auxgroup.com';
	Fly.config.timeout = 8000;
	Fly.config.withCredentials = false;
	return Fly.get(`/oauth/token/dec/${token}`).then((res) => {
		return res;
	});
};

// 获取登陆人的本系统信息
export const getUserEventInfo = () => {
	return Fly.get('/standard/educational/detail/selfEduData').then((res) => {
		return res;
	});
};
// 获取打印日志显示
export const getConsole = () => {
	return Fly.get('/standard/sysconfig/eduTeacherStudentConfig/queryConsole?noPp=1').then((res) => {
		return res;
	});
};
// 单独 获取用人激励
export const getMotivation = () => {
	return Fly.post('/standard/educational/pms/seletMotivation').then((res) => {
		return res;
	});
};
export const getLoginInfoDingDing = (token) => {
	Fly.config.baseURL = 'https://open.auxgroup.com';
	Fly.config.withCredentials = false;
	return Fly.get(`/ding/auth/token/dec/${token}`).then((res) => {
		return res;
	});
};
export const getLoginInfoDetail = () => {
	return Fly.get('/standard/educational/detail/selfEduDetail').then((res) => {
		return res;
	});
};
// 人员列表
export const AllPersonList = () => {
	return Fly.get('/standard/educational/event/selectPersonnel').then((res) => {
		return res;
	});
};
// 特定人员搜索（姓名或工号）
export const PersonListByName = (data) => {
	return Fly.get(`/standard/educational/event/queryPersonnel/${data}`).then((res) => {
		return res;
	});
};
// 图片附件上传
export const imgUpload = (postData) => {
	return Fly.post('/standard/homepage/uploadImg', postData).then((res) => {
		return res;
	});
};
export const saveEvent = (postData) => {
	return Fly.post('/standard/educational/event/saveEvent', postData).then((res) => {
		return res;
	});
};
export const updateEvent = (postData) => {
	return Fly.post('/standard/educational/event/updateEvent', postData).then((res) => {
		return res;
	});
};
export const getChangeOptionsList = (type, seletNumer) => {
	return Fly.get(`/standard/educational/event/queryEduElement/${type}/${seletNumer}`).then((res) => {
		return res;
	});
};
// 首页 育人列表数据
export const Index_getEducateList = () => {
	return Fly.get('/standard/educational/detail/todoEdu').then((res) => {
		return res;
	});
};
export const Index_getEducatedList = () => {
	return Fly.get('/standard/educational/detail/acceptEdu').then((res) => {
		return res;
	});
};

// 根据事件id 获取事件详情
export const getTodoDetail = (id) => {
	return Fly.get(`/standard/educational/detail/queryEventDetail/${id}`).then((res) => {
		return res;
	});
};
export const updateTodoDetail_agree = (data) => {
	return Fly.post('/standard/educational/event/processAffirm', data).then((res) => {
		return res;
	});
};
export const updateTodoDetail_rejected = (data) => {
	return Fly.post('/standard/educational/event/processRejected', data).then((res) => {
		return res;
	});
};
export const updateTodoDetail_del = (data) => {
	return Fly.post('/standard/educational/event/processAbandoned', data).then((res) => {
		return res;
	});
};

// 待办列表

export const TodoList_getnum = () => {
	return Fly.get('/standard/educational/todo/count').then((res) => {
		return res;
	});
};
export const TodoList_getAcceptEdu = () => {
	return Fly.get('/standard/educational/todo/acceptEdu').then((res) => {
		return res;
	});
};
export const TodoList_getTodoEdu = () => {
	return Fly.get('/standard/educational/todo/todoEdu').then((res) => {
		return res;
	});
};

// 徒弟个人详情

export const GetPersonMess = (str) => {
	return Fly.get('/standard/educational/detail/studentEduDetail/' + str).then((res) => {
		return res;
	});
};
export const GetOptionMess = (postData) => {
	return Fly.post('/standard/educational/detail/viewElementEvent', postData).then((res) => {
		return res;
	});
};
// 重复录入检查
export const ckeckRepeat = (postData) => {
	return Fly.post('/standard/educational/event/checkRepeat', postData).then((res) => {
		return res;
	});
};

// 被育 列表

export const GetListDone = (postData) => {
	return Fly.get('/standard/educational/detail/selfAcceptEduDetail', postData).then((res) => {
		return res;
	});
};
export const GetListDo = (postData) => {
	return Fly.get('/standard/educational/detail/selfToduEduDetail', postData).then((res) => {
		return res;
	});
};

export const GetActivityRank = (data) => {
	return Fly.get(`/papi/activity/rank/${data}`).then((res) => {
		return res
	})
}

export const CheckRealNameAuth = () => {
	return Fly.get('/papi/user/check-authentication').then((res) => {
		return res
	})
}

export const CheckLogin = () => {
	return Fly.get('/papi/user/check-login').then((res) => {
		return res
	})
}

export const GetQRCode = (data) => {
	return Fly.get('/API/activity/qrcode', data).then((res) => {
		return res
	})
}

export const JoinActivity = (data) => {
	return Fly.get(`/papi/activity/join/${data}`).then((res) => {
		return res
	})
}

export const GetActivityDetail = (data) => {
	return Fly.get(`/papi/activity/${data}?goods_id=1541774593384591361`).then((res) => {
		return res
	})
}

export const GetWxConfig = (data) => {
	return Fly.get(`/papi/order/sign?url=${data}`).then((res) => {
		return res
	})
}

export const Pay = (data) => {
	return Fly.get('/papi/order/pay', data).then((res) => {
		return res
	})
}

export const showEntrance = () =>{
	return Fly.get('/papi/order/show-entrance').then((res)=>{
		return res
	})
}
