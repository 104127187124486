import React from "react";
import ReactDOM from "react-dom";
import LoadingNode from "./loading.jsx";
import fly from "flyio";

import { createHashHistory } from "history";
import store from "../store/index";
import { changeLoading } from "../store/actionCreates";
ReactDOM.render(<LoadingNode />, document.getElementById("loading"));
//添加请求拦截器
let TOKEN = window.sessionStorage.getItem("TOKEN");
fly.config.timeout = 5000;
fly.config.headers = {
  Accept: "application/json",
  "Content-Type": "application/json;charset=utf-8",
};
fly.config.withCredentials = true;
// fly.config.baseURL = process.env.REACT_APP_DEVBASE;
fly.interceptors.request.use((request) => {
  let ENV = process.env;
  // if(ENV.NODE_ENV === 'development'){
  //   request.url = request.url.replace("/API/",'/')
  // }
  // console.log(request,ENV);
  store.dispatch(changeLoading(true));
  // let xhr = new fly.engine();
  // xhr.upload.onprogress = function (event) {
  // 	// body...
  // 	console.log(event);
  // };
  // console.log(xhr);
  // 设置超时时间
  //给所有请求添加自定义header
  let thisDev = window.location.hash.split("/");
  let YOYOTOKEN = window.sessionStorage.getItem("YOYOTOKEN"),PCTOKEN=window.sessionStorage.getItem("PC-YOYOTOKEN");
  if (YOYOTOKEN&&thisDev[1]==='phone') {
    request.headers["User-Authorization"] = YOYOTOKEN;
  }
  if(PCTOKEN&&thisDev[1]==='pc'){
    request.headers["Web-Authorization"] = PCTOKEN;
  }
  // request.headers['X-Ding-Token'] = dingToken;
  //打印出请求体
  //终止请求
  //var err=new Error("xxx")
  //err.request=request
  //return Promise.reject(new Error(""))

  //可以显式返回request, 也可以不返回，没有返回值时拦截器中默认返回request
  return request;
});

//添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {

    store.dispatch(changeLoading(false));
    let thisDev = window.location.hash.split("/");
    let History = new createHashHistory();
    if(response.data.code === 1003&&thisDev[1]==='pc'){
      // 重新登录
      window.sessionStorage.removeItem("PC-YOYOTOKEN");
      History.replace("/pc/login");
    }
    if(response.data.code === 1003&&thisDev[1]==='phone'){
      
      // 重新登录
      window.sessionStorage.removeItem("YOYOTOKEN");
      window.localStorage.removeItem("YOYOTOKEN");
      window.localStorage.removeItem("user");
      History.replace('/phone/login');
    }
    //只将请求结果的data字段返回
    return response.data;
  },
  (err) => {
    //发生网络错误后会走到这里
    //return Promise.resolve("ssss")
    window.history.pushState({}, "/phone/mine");
  }
);
export default fly;
