import store from '../../store';
import { CHANGE_USER } from '../../store/actionTypes';
import { changeUserAction } from '../../store/actionCreates';

import { getLoginInfo } from '../../fly/apis';
let _const = this;
class Token {
  get() {
    let c_name = 'wechat-token';
    let c_end;
    console.warn('document.cookie 是否写入wechat-token', document.cookie, document.cookie.indexOf(c_name + '='));
    if (document.cookie.length > 0) {
      var c_start = document.cookie.indexOf(c_name + '=');
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        c_end = document.cookie.indexOf(';', c_start);
        if (c_end == -1) c_end = document.cookie.length;
        return unescape(document.cookie.substring(c_start, c_end));
      }
    } else {
      window.location.reload();
    }
  }
  isWeChat() {
    // 判断是否是移动端
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true;
    } else {
      return false;
    }
  }
}
let tokenObj = new Token();
let userMess = new Object();
export const login = () => {
  if (tokenObj.isWeChat() && process.env.REACT_APP_DEV_TEST != 'true') {
    // WeChat 版
    let token = tokenObj.get();
    console.log('wecaht');
    if (token) {
      window.sessionStorage.setItem('TOKEN', token);
      console.log('获取到Token');
      // 根据token请求用户信息
      getLoginInfo(token).then((res) => {
        userMess = res;
        if (userMess.extattr.length > 0) {
          userMess.extattr.map((item) => {
            if (item.name == '工号') {
              window.sessionStorage.setItem('employeeId', item.value);
            }
          });
        }
        store.dispatch(changeUserAction(userMess));
        window.sessionStorage.setItem('userMess', JSON.stringify(res));
      });
    } else if (window.sessionStorage.getItem('TOKEN')) {
      getLoginInfo(window.sessionStorage.getItem('TOKEN')).then((res) => {
        userMess = res;
        if (userMess.extattr.length > 0) {
          userMess.extattr.map((item) => {
            if (item.name == '工号') {
              window.sessionStorage.setItem('employeeId', item.value);
            }
          });
        }
        store.dispatch(changeUserAction(userMess));
        window.sessionStorage.setItem('userMess', JSON.stringify(res));
        console.log('已有token:', window.sessionStorage.getItem('TOKEN'));
      });
    } else {
      let url = window.location.href,
        thisUrl = window.location.href;
      if (url.indexOf('#')) {
        url = url.replace('#/', '');
      }
      let strUrl = thisUrl.split('#');
      let openUrl = `https://open.auxgroup.com/oauth/oauth2url?appurl=${url}/HASH/${strUrl[1]}`;
      window.location.href = openUrl;
      console.log('微信跳转认证');
    }
    // resolve();
  } else if (process.env.NODE_ENV == 'production' && tokenObj.isWeChat() == false) {
    // 线上环境且为浏览器环境
    alert('登陆：请在手机微信或钉钉打开');
    return false;
  } else if (process.env.REACT_APP_DEV_TEST == 'true') {
    // 本地开发版
    // 王东生
    // let token = 'qSv04K0ObcMVUJ-cVAYo_BSptir0Rvjxs8eXYnd5pa9JVyVcAt5YHBP5Pj-UIubEUp3JRiFysyb1IJhN-kXvoLibEUbaHnUs0DmrjCqOI84UpvGrId6qQ62bDV4IzwpQTQ9vyBrpwYwGL82_xd654NK7kleEeGVv7jzSlF6eD5kKV45HIEvYE9vOVoVM185BZHrNyLupaj-D1mQDOIyGk7sgTDoGBZPD0JGDgd4ZhSBtCZ5Pp0k06ySW_-TDLyWYa-U7yltZ-ZAWmfPL6NiU1IYRcCHuNKjGCIROw3Z2sJZgy8kcpJwj8QG1v_tuNjOmvUnY45lxeyrpyCTFrl-9TvrxlXvnhGDM'
    // 汪工
    // let token = 'qSv04K0ObcMcoTT3K2nkhcnakFlf8OEes8eXYnd5pa9JVyVcAt5YHPlO0Mn59ZYHCHdE_3vo7D-QVotKqbebkuSF_zF215FQrl-RdCvMCvAWVVbekaJkvKbD-q7TZ9VQIeE1G0wTnUnVUb8SmedBxoCQzW_hz2lM5J1VGCF6ASu6n_e8VPPWc-tseOPo_-CHBi_Nv8XeueA-n9q8EtOc0_hmHoKubovn3s8gABMxrZG_Gz4a9DX0YPeXNhhk4uAgQLMSoLMEk8P9LFSg2ZS3sN3GdkUWGDzB0qxg1gOezlqjiHWMwaqRPBaZ88vo2JTUNtfeykT4Enai7LsT0I1phmDLyRyknCPxQNZ12MdygFQfnQSuWXvQUenIJMWuX71O-vGVe-eEYMw'
    // 王延棋
    let token =
      'mGQtpdhxnhW8ycPAAFjTu4kgq2nBZPnbfYz5TLXWkXGFAhB3nUgD0N6I80VMSNUG3RDD7LPl_v1V927SwFpK2E6NBQTbs7CDjTPtT8elYUO4mxFG2h51LNA5q4wqjiPOFKbxqyHeqkOtmw1eCM8KUI0p6Ia5lEUKwRP4jvjG7qOEThLNp0CKC8MzG9I3oUIjv3d862iQsPUxjU4ssoy9g9eJMYG1bqQ3lcPorhOPU_X3f4XPm1dyGWQy2meuiNtEMY1OLLKMvYMrZd0fHzF-05XD6K4Tj1P1Qame_GKjwHzQF8dW0QoB6Fj-FdLdYn-C3sGvRoldtAF3Q07D8a_u05pcPMYnOXnSdetJZyvpq06OYMK274_vYutnsEyRGCwbp9T7sesRe57h8RVWKQIsmsCmmqfO5syxQLMSoLMEk8MFdoTM0WIsYZSZXebGXkyrIGMZ-ELx3T0r1GyaOWCMdHK94GATZ6fLIDw-inwfqSlboPYLiLhwbzVQeOAAc6dVrktBLXcnActluhonnZ5PXJjXrp_Wo9O-';
    // 孙玲雯
    // let token = 'qSv04K0ObcM6elim6BsBI6NySlCNCZP_s8eXYnd5pa9JVyVcAt5YHCxR9bf1GU1LHdcONaMueL0xjU4ssoy9g0qhYEF0RnCHrl-RdCvMCvAWVVbekaJkvKbD-q7TZ9VQIeE1G0wTnUnVUb8SmedBxoPskjqv4j6G0Cmyy5foMly6n_e8VPPWc-F1cWoL3ZfDn0r2HcTrkwOAlS8_-qmsYcPqoB8qko9XrjRv2JTgL7cOJ8hKv64xGN-f-FP3MHnrIimNdApDSl6ZIpjfk7a9LH8q2PtCUKjy1w2NzL8YW73EpP289njhhVXioVMhxEOzJNvqONBLt_4ljJXZt9qSxRhj-1wNZskETcYMY97QS7i-HkLbE8cfacepnb_DLvsfwi30LPQSEhozivtgvBHMt06PZloAXcWIXAEueCjOQ_jgdiT_rqBEfv9RBBi4PTsO4GT_0i1uUwfk7jQSB8fbcJbEJ35tSag3D_6e_4KoKz19uBISzcAH-10pWsVuHP2gAd9z5dd8qAyZ_wmzacjra8QetoNRAGcO'
    // 符铮
    // let token = "qSv04K0ObcO9QktO9rQFbjquANnK5g2us8eXYnd5pa9JVyVcAt5YHCxR9bf1GU1LqbIPlc6HrE6q1ZTkNXjmrTqMqpwe5WSsuJsRRtoedSzQOauMKo4jzhSm8ash3qpDrZsNXgjPClB8t5qgSGRaHlUqHjgfL6PrSqMpo_6mSHRYcaFwN6FuzbFN8i3kqiE_IfL-08FMD1nBE_iO-MbuoyKO7Efm6u4PwzMb0jehQiO9QktO9rQFbkrLlsZ5h1PF4H35jRjWixU58ejaKPHvtuzcwtLEmpIR2W162lafOtMtVcK7kuMokZYBZYbmxq1U5daFmb333fr2HvDJvJX_MszLbZFLBTuRXkVpq41vyIZph5sjtTjW3RqQw-zm3G9-tnOIQn0NBbhW6PEluvwn1_d5fOGXsVZx"
    // 莫扬州(空token)
    // let token = 'mGQtpdhxnhVpHJC1jj1kRAiseWUkO17KKs-m1vexkwkLg2XTfExoqvdVJO0WEtmtqOpitNm46xtn3A5lb_sTagCMYNj-CcsBG4AZ-N_RjZXbzlaFTNfOQXne75kDIDv6Bi_Nv8XeueAXsZhdBC54rvhmHoKubovndnDv-5jQg5AJDQkzPqI3H-zLjZ49vANc1VG_EpnnQcZJc2GVJAw7mNLBigBCWN2E8RmWLmvrzFTEdpeUjmqTT5xskGQGqSC3ByfBypWsK0RtCZ5Pp0k0631fmmll9CtE5MEWtgiHHymMraXLmj126I2qIiZ1dVR39U1lIT1R4SgNc0K8lTQXhT8R6KgNheIclWPlpHkj1AKtsMkg3Sgol0bNQokpxs871w-EcziOPu3KWGsRnCOrdq9mDkK-l0rDCdFb60quY-daEHjWr0CmnOTuNBIHx9twHtWXYM0KVXHT8sSkjzDB5oVTR6Cg-K7I7nkk8WBKJ1WawNRz_qYmnmW6Giednk9cmNeun9aj074'
    // let token = 'qSv04K0ObcM9tolJdXu5Z9LEIFhelyu3s8eXYnd5pa9JVyVcAt5YHP94KDYK3RdrTLSOflrXR2tA7rMcNqNvP-Cj4j5wr46Mrl-RdCvMCvAWVVbekaJkvKbD-q7TZ9VQIeE1G0wTnUnVUb8SmedBxol-PXWJmm-ESx8cow7MVPS6n_e8VPPWc-F1cWoL3ZfDn0r2HcTrkwOAlS8_-qmsYcPqoB8qko9XrjRv2JTgL7dAIFyurZJ2eW6tp9PIvd_oIimNdApDSl6ZIpjfk7a9LH8q2PtCUKjy1w2NzL8YW73EpP289njhhVXioVMhxEOzJNvqONBLt_587_cJEu560RAUwhcRZqNFjDESUGgv5SjDcQ41lTn7hsepnb_DLvsfwi30LPQSEhozivtgvBHMt_LFqlkR7EhfMvBur5_C42FHq_dmPF3PH-TuNBIHx9twlsQnfm1JqDcP_p7_gqgrPX24EhLNwAf74xls8imbprq9xwLJWFPpPunIJMWuX71O-vGVe-eEYMw'
    window.sessionStorage.setItem('TOKEN', token);
    getLoginInfo(token).then((res) => {
      userMess = res;
      if (userMess.extattr.length > 0) {
        userMess.extattr.map((item) => {
          if (item.name == '工号') {
            window.sessionStorage.setItem('employeeId', item.value);
          }
        });
      }
      store.dispatch(changeUserAction(userMess));
      window.sessionStorage.setItem('userMess', JSON.stringify(res));
    });
    // resolve();
  }
};
