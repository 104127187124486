// 管理动作
import { CHANGE_LOADING, CHANGE_USER, CHANGE_THEME, CHANGE_PERSONLIST, CHANGE_EVENTMESS, CHANGE_SELECT_OPTIONS } from './actionTypes';
const defaultState = {
	LOADING: false,
	// 默认值
	USER: null,
	THEME: 'light',
	CHANGEPERSON: null,
	EVENTMESS: null,
	// 选中的选项数据
	SELECTOPTION: null,
	menu: [
		{
			title: "订单管理",
			key: "1",
			router:"/pc/orderList"
		}, {
			title: "商品管理",
			key: "2",
			router:"/pc/goodsList"
		}, {
			title: "公告中心",
			key: "3",
			router:"/pc/noticeList"
		}, {
			title: "用户管理",
			key: "4",
			router:"/pc/userList"
		}
	]
};
export default (state = defaultState, action) => {
	if (action.type === CHANGE_LOADING) {
		const newState = JSON.parse(JSON.stringify(state));
		newState.LOADING = action.loading;
		return newState;
	}
	if (action.type === CHANGE_USER) {
		const newState = JSON.parse(JSON.stringify(state));
		newState.USER = action.mess;
		return newState;
	}
	if (action.type === CHANGE_THEME) {
		const newState = JSON.parse(JSON.stringify(state));
		newState.THEME = action.theme;
		return newState;
	}
	if (action.type === CHANGE_PERSONLIST) {
		const newState = JSON.parse(JSON.stringify(state));
		newState.CHANGEPERSON = action.changePerson;
		return newState;
	}
	if (action.type === CHANGE_EVENTMESS) {
		const newState = JSON.parse(JSON.stringify(state));
		newState.EVENTMESS = action.eventMess;
		return newState;
	}
	// 更新选中的选项数据
	if (action.type === CHANGE_SELECT_OPTIONS) {
		const newState = JSON.parse(JSON.stringify(state));
		newState.SELECTOPTION = action.optionsMess;
		return newState;
	}
	return state;
};
