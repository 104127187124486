import store from '../../store';
import { CHANGE_USER } from '../../store/actionTypes';
import { changeUserAction } from '../../store/actionCreates';

import { getLoginInfoDingDing } from '../../fly/apis.js';
import * as dd from 'dingtalk-jsapi';
class Token {
  get() {
    let c_name = 'wechat-token';
    let c_end;
    if (document.cookie.length > 0) {
      var c_start = document.cookie.indexOf(c_name + '=');
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        c_end = document.cookie.indexOf(';', c_start);
        if (c_end == -1) c_end = document.cookie.length;
        return unescape(document.cookie.substring(c_start, c_end));
      }
    } else {
      window.location.reload();
    }
  }
}
let tokenObj = new Token();

export const DingLogin = async () => {
  if (process.env.NODE_ENV == 'production') {
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setLeft({
        control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
        text: '关闭', //控制显示文本，空字符串表示显示默认文本
        onSuccess: function (result) {
          dd.biz.navigation.close({
            onSuccess: function (result) {},
            onFail: function (err) {}
          });
          /*
        {}
        */
          //如果control为true，则onSuccess将在发生按钮点击事件被回调
        },
        onFail: function (err) {}
      });
      // 钉钉登录
      let token = tokenObj.get() || window.sessionStorage.getItem('X-Ding-Token');
      if (token) {
        window.sessionStorage.setItem('X-Ding-Token', token);
        // 页面内获取到token
        let asyncData = await getLoginInfoDingDing(token).then((userMess) => {
          window.sessionStorage.setItem('userMess', userMess);
          let Obj = JSON.parse(userMess);
          window.sessionStorage.setItem('employeeId', Obj.badge);
          store.dispatch(changeUserAction(Obj));
          return 1;
        });
        return asyncData;
      } else {
        // 无法获取token
        let url = window.location.href,
          thisUrl = window.location.href,
          id = 0;
        if (url.indexOf('#')) {
          url = url.replace('#/', '');
        }
        const hostname = window.location.hostname;
        if (hostname == 'srbzqtest.auxgroup.com') {
          //测试
          id = '866635834';
        } else {
          // 生产
          id = '866635834';
        }
        let strUrl = thisUrl.split('#');
        let openUrl = `https://open.auxgroup.com/ding/auth/auth2url?agentId=${id}&appurl=${strUrl[0]}HASH${strUrl[1]}`;
        window.location.href = openUrl;
      }
    }
  } else {
    // let token = "qSv04K0ObcMakqDlKR_j6AYlF-Rdyt7Es8eXYnd5pa9JVyVcAt5YHObSBI_Gz1fj56T0o66u0d2QVotKqbebklzWegF73DBfuJsRRtoedSzQOauMKo4jzhSm8ash3qpDrZsNXgjPClBgceZFjw07RvtLDV0Tj5xKSqMpo_6mSHRYcaFwN6FuzbQXjQvMyjQbXXkqc3-Y463h6Tuzhuqy3eubx-9U5aFEOaEA5t86d2YAMx8x4Ijlm42qIiZ1dVR3X_wstDCr1A1ArMaQHsOVa1Kxo6C6HeukvPubZsLUepQZmV1d8KZq4eHhYB57euxeKkBzlMMs9puUl66Qs05T-WrskSsPHikVhCj2gYiiLpQ4bvCGigICP7qw6q1K8FbWMU7VyzEoYEggrvtEqj-KzDqgx9ibAucK6TnzLPmVFXcAWWKguxOjdeTuNBIHx9twlsQnfm1JqDcP_p7_gqgrPX24EhLNwAf7GpKg5Skf4-hezWIL7-9xNi-jes0SgMDwfRBfZXzs3fA"
    // 王东生
    // 王延棋
    let token =
      'qSv04K0ObcPmLmtvFpJc9dtlFwt8kHNUFNy5bnTdeDm-LbkFEp8_mE0Gi0JA2auFiZfS7kYnoikdxQrI9GwT5xcQPNxXUcMlBw2t49TU7x4CuWd5uJkkRAYvzb_F3rng5qzri3moZ9z4Zh6Crm6L56HNwwJiD1_C1PEMkV8zN5IGL82_xd654NK7kleEeGVv7jzSlF6eD5kKV45HIEvYE9vOVoVM185BYq6FHgfXPRiYZlaTWaGYsiIpjXQKQ0pemSKY35O2vSx_Ktj7QlCo8tcNjcy_GFu9xKT9vPZ44YVV4qFTIcRDsyTb6jjQS7f-qFYPuFUn0zAODXPA9hJz56rLR0pkv2DF6XMxaU8GPQfHqZ2_wy77H8It9Cz0EhIaM4r7YLwRzLfIPTFnP9QTR3K94GATZ6fL0SXCdrBgzawc7SvWvpq7XR2eWAhfwvkH93-Fz5tXchn_3JsSAXLA2y-jes0SgMDwfRBfZXzs3fA';
    // 孙玲雯
    // let token = 'qSv04K0ObcM6elim6BsBI6NySlCNCZP_s8eXYnd5pa9JVyVcAt5YHCxR9bf1GU1LHdcONaMueL0xjU4ssoy9g0qhYEF0RnCHrl-RdCvMCvAWVVbekaJkvKbD-q7TZ9VQIeE1G0wTnUnVUb8SmedBxoPskjqv4j6G0Cmyy5foMly6n_e8VPPWc-F1cWoL3ZfDn0r2HcTrkwOAlS8_-qmsYcPqoB8qko9XrjRv2JTgL7cOJ8hKv64xGN-f-FP3MHnrIimNdApDSl6ZIpjfk7a9LH8q2PtCUKjy1w2NzL8YW73EpP289njhhVXioVMhxEOzJNvqONBLt_4ljJXZt9qSxRhj-1wNZskETcYMY97QS7i-HkLbE8cfacepnb_DLvsfwi30LPQSEhozivtgvBHMt06PZloAXcWIXAEueCjOQ_jgdiT_rqBEfv9RBBi4PTsO4GT_0i1uUwfk7jQSB8fbcJbEJ35tSag3D_6e_4KoKz19uBISzcAH-10pWsVuHP2gAd9z5dd8qAyZ_wmzacjra8QetoNRAGcO'
    // 符铮
    // let token = "qSv04K0ObcO9QktO9rQFbjquANnK5g2us8eXYnd5pa9JVyVcAt5YHCxR9bf1GU1LqbIPlc6HrE6q1ZTkNXjmrTqMqpwe5WSsuJsRRtoedSzQOauMKo4jzhSm8ash3qpDrZsNXgjPClB8t5qgSGRaHlUqHjgfL6PrSqMpo_6mSHRYcaFwN6FuzbFN8i3kqiE_IfL-08FMD1nBE_iO-MbuoyKO7Efm6u4PwzMb0jehQiO9QktO9rQFbkrLlsZ5h1PF4H35jRjWixU58ejaKPHvtuzcwtLEmpIR2W162lafOtMtVcK7kuMokZYBZYbmxq1U5daFmb333fr2HvDJvJX_MszLbZFLBTuRXkVpq41vyIZph5sjtTjW3RqQw-zm3G9-tnOIQn0NBbhW6PEluvwn1_d5fOGXsVZx"
    window.sessionStorage.setItem('X-Ding-Token', token);
    let asyncData = await getLoginInfoDingDing(token).then((userMess) => {
      window.sessionStorage.setItem('userMess', userMess);
      let Obj = JSON.parse(userMess);
      window.sessionStorage.setItem('employeeId', Obj.badge);
      store.dispatch(changeUserAction(Obj));
      return 1;
    });
    return asyncData;
  }
};
