import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

// import * as dd from 'dingtalk-jsapi';

import store from './store';
import { CHANGE_THEME } from './store/actionTypes';
import { changeTheme } from './store/actionCreates';
import { createTheme } from '@material-ui/core/styles'
import {  ThemeProvider } from '@material-ui/core/styles';
import('./index.css');
import AppRouter from './route/index.js';
import { login } from './static/js/login.js';
import { DingLogin } from './static/js/DingLogin.js';

import VConsole from 'vconsole';
import { getConsole, getLoginInfoDetail } from './fly/apis.js';
// import 必须在所有业务之前
let NowDate = new Date().getHours(),
	themeType;
if (NowDate > 6 && NowDate < 19) {
	themeType = 'light';
} else {
	themeType = 'dark';
}
// 暂时主题一直为白天
themeType = 'light';
store.dispatch(changeTheme(themeType));
const theme = createTheme({
	palette: {
		type: themeType
	}
});

// if (dd.env.platform == 'notInDingTalk') {
// 	login();
// }
// DingLogin().then((res) => {
// 	if (res == 1) {
// 		console.log("完成登陆");
// 		getConsole().then((res) => {
// 			if (res.code == 200) {
// 				// 后台数据库控制是否显示
// 				let consoleShow;
// 				res.msg == 'true' ? (consoleShow = true) : (consoleShow = false);
// 				if (process.env.NODE_ENV === 'production') {
// 					if (consoleShow) {
// 						var vConsole = new VConsole();
// 					}
// 				}
// 			}
// 		});
// 		const isMobile = () => {
// 				var ua = window.navigator.userAgent.toLowerCase();
// 				if (ua.match(/MicroMessenger/i) == 'micromessenger' || dd.env.platform != 'notInDingTalk') {
// 					return true;
// 				} else {
// 					return false;
// 				}
// 			},
// 			hostname = window.location.hostname.toLowerCase();
// 		// #212121
// 		if (hostname != 'localhost' && isMobile() == false) {
// 		} else {
// 			ReactDOM.render(
// 				// 关闭react严格模式
// 				<ThemeProvider theme={theme}>
// 					<AppRouter />
// 				</ThemeProvider>,
// 				document.getElementById('root')
// 			);
// 		}
// 	}
// });
ReactDOM.render(
	// 关闭react严格模式
	<ThemeProvider theme={theme}>
		<AppRouter />
	</ThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
