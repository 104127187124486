import React from 'react';
import state from '../store';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import * as dd from 'dingtalk-jsapi';

import RouterArr from './compents.js';
import None from './404.jsx';
// pc
import MenuCom from "../components/pc/index";
let newRouterArr = [];
function MakeRouterObj(arr) {
	arr.forEach((item, index) => {
		if (item.children && item.children.length !== 0) {
			item.children.forEach((e) => {
				e.path = item.path + '/' + e.path;
			});
			MakeRouterObj(item.children);
		}
		newRouterArr.push(item);
	});
	return newRouterArr;
}
const BodyStyleView = (obj) => {
	let BodyView = document.getElementsByTagName("body")[0];
	BodyView.style.backgroundColor = obj.backgroundColor;
}
const LeanStyle = () => {
	let BodyView = document.getElementsByTagName("body")[0];
	BodyView.style = {}
}



const testView = () => {
	return (
		<span>111</span>
	)
}
export default function App_router(argument) {

	return (
		<Router>
			<Switch>
				{MakeRouterObj(RouterArr).map((item, index) => {
					return (
						<Route
							exact
							key={'_' + item.name + '_' + index}
							path={item.path}
							render={(routeProps) => {
								// 监听路由时机
								let title = item.meta.title;
								const href = routeProps.location.pathname.split('/');
								if(href[1] === 'phone'){
									const Phone_token_sessinon = window.sessionStorage.getItem("YOYOTOKEN");
									const Phone_token_local = window.localStorage.getItem("YOYOTOKEN");
									const Phone_User_local = window.localStorage.getItem('user');
									if(Phone_token_sessinon === null){
										window.sessionStorage.setItem("YOYOTOKEN",Phone_token_local);
										window.sessionStorage.setItem("user",Phone_User_local);
									}
								}
								if(href[1] === 'pc'){
									const Pc_token_sessinon = window.sessionStorage.getItem("PC-YOYOTOKEN");
									const Pc_token_local = window.localStorage.getItem("PC-YOYOTOKEN");
									const Pc_User_local = window.localStorage.getItem('user');
									if([null,'null'].includes(Pc_token_sessinon)){
										window.sessionStorage.setItem("PC-YOYOTOKEN",Pc_token_local);
										window.sessionStorage.setItem("user",Pc_User_local);
									}
								}
								document.title = title;
								let token = window.sessionStorage.getItem("YOYOTOKEN") || window.sessionStorage.getItem("PC-YOYOTOKEN");
								// 						if (dd.env.platform !== 'notInDingTalk') {
								// 							dd.biz.navigation.setTitle({
								// 								title: title, //控制标题文本，空字符串表示显示默认文本
								// 								onSuccess: function (result) {
								// 									/*结构
								// {
								// }*/
								// 								},
								// 								onFail: function (err) { }
								// 							});
								// 						} else {
								// 							document.title = title;
								// 						}
								if (item.meta.token && token == null) {
									return <Redirect to={item.meta.root} />;
								} else {
									return (
										<item.component {...routeProps}>
											{
												// document.getElementsByTagName("body")[0]
												// 进入页面时路由
												item.meta.bodyStyle ? BodyStyleView(item.meta.bodyStyle) : LeanStyle()
											}

										</item.component>
									);
								}
							}}
						// component={
						// 	MenuCom
						// }
						>

							{/* {item.component === true ? 
							<MenuCom
							render={<testView />}
							>
							</MenuCom> : null} */}



						</Route>
					);
				})}
				<Route path="/urlNone">
					<None />
				</Route>
				{/* <Redirect to="/urlNone" /> */}
			</Switch>
		</Router>
	);
}
