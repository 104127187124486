import Fly from './index.js';
export const FlyObj = Fly;
// 获取用户列表
export const get_user_list = (data)=>{
    return Fly.get("/API/user/query",data).then((res)=>{
        return res;
    })
}
// /user/authentication 身份证验证
export const authenticationApiEvent = (data)=>{
    return Fly.post("/API/user/authentication",data).then((res)=>{
        return res;
    })
}
// /user/remakePwd 用户重置密码
export const user_remakePwd = (data)=>{
    return Fly.post("/API/user/remakePwd",data).then((res)=>{
        return res;
    })
}
// /user/updatePwd 用户更改密码
export const updatePassword = (data)=>{
    return Fly.post("/API/user/updatePwd",data).then((res)=>{
        return res;
    })
}

// 首页活动列表接口
export const Getactivity = () =>{
	return Fly.get('/papi/activity').then((res) => {
		return res;
	});
}

// 藏品详情
export const Transfer = (data) => {
    return Fly.get('/papi/collection/transfer', data).then((res) => {
        return res
    })
}


// 更新用户状态POST /user/update

export const Update_user = (data)=>{
    return Fly.post("/API/user/update",data).then((res)=>{
        return res;
    })
}

// file upload
export const fileUpload = (data)=>{
    return Fly.post("/API/common/upload",data).then((res)=>{
        return res;
    })
}


// phone
// 注册
export const phone_register = (data) => {
	return Fly.post('/API/user/register',data).then((res) => {
		return res;
	});
    // return axios.post("/API/user/register").then((res)=>{
    //     return res;
    // })
};
// 登陆
export const phone_login = (data)=>{
    return Fly.post("/API/user/login",data).then((res)=>{
        return res;
    })
}
// 获取验证码
export const getsmc = (str)=>{
    return Fly.post("/API/common/send/sms?mobile="+str).then((res)=>{
        return res;
    })
}
// /user/get-info
export const phone_get_info = (str)=>{
    return Fly.post("/API/user/get-info").then((res)=>{
        return res;
    })
}

// 校验是否已实名认证
export const checkAuthentication = () =>{
    return Fly.get("/papi/user/check-authentication").then((res)=>{
        return res;
    })
}

// 我的盲盒
export const blindbox = (data) => {
	return Fly.get("/papi/blindbox/mine", data).then((res)=>{
        return res;
    })
}

// 开盲盒
export const blindboxOpen = (data) => {
    return Fly.post("/papi/blindbox/open", data).then((res)=>{
        return res;
    })
}

// 保存收货地址
export const saveUserAddress = (data) => {
    return Fly.post("/papi/saveUserAddress", data).then((res)=>{
        return res;
    })
}

// 获取收货地址
export const getUserAddress = () => {
    return Fly.get("/papi/getUserAddress").then((res)=>{
        return res;
    })
}

// 判断中奖
export const checkPrize = () => {
    return Fly.get("/papi/checkPrize").then((res)=>{
        return res;
    })
}

// 标记通知已读
export const luckyPrizeRead = (data) => {
    return Fly.get("/papi/luckyPrize/read", data).then((res)=>{
        return res;
    })
} 

// 获取个人区块链地址
export const getUserChain = () => {
    return Fly.get("/papi/user/chain").then((res)=>{
        return res;
    })
}

// 查找用户
export const searchUser = (data) => {
    return Fly.get("/papi/user/search", data).then((res)=>{
        return res;
    })
}

// 转赠
export const ddcTransfer = (data) => {
    return Fly.post("/papi/user/ddc-transfer", data).then((res)=>{
        return res;
    })
}

// 转赠记录
export const ddcTransferRecord = (data) => {
    return Fly.get("/papi/user/ddc-transfer/record", data).then((res)=>{
        return res;
    }) 
}

// 获取公众号二维码
export const getShareCode = (data) => {
    return Fly.get("/papi/wx/getShareCode", data).then((res)=>{
        return res;
    })
}

// 合成列表
export const goodsMerges = (data) => {
    return Fly.get("/papi/goods/merges", data).then((res)=>{
        return res;
    })
}

// 合成活动详情
export const goodsMergesDetail = (data) => {
    return Fly.get(`/papi/goods/merges/${data.activity_id}`).then((res)=>{
        return res;
    })
}

// 合成记录
export const userMerges = (data) => {
    return Fly.get(`/papi/user/merges`, data).then((res)=>{
        return res;
    })
}

// 合成
export const mergeGoods = (data) => {
    return Fly.post(`/papi/user/merge-goods/${data.activity_id}`).then((res)=>{
        return res;
    }) 
}

// 优先购权益列表
export const forwardBuy = (data) => {
    return Fly.get('/papi/interest/forward-buy', data).then((res)=>{
        return res;
    }) 
}

// 忘记密码获取验证码
export const getForgetPwdCode = (data) => {
    return Fly.post('/API/user/forgetPwdCode', data).then((res)=>{
        return res;
    }) 
}

// 忘记密码提交验证码
export const checkForgetPwdCode = (data) => {
    return Fly.post('/API/user/checkCode', data).then((res)=>{
        return res;
    }) 
}

// 重置密码
export const resetPwd = (data) => {
    return Fly.post('/API/user/resetPwd', data).then((res)=>{
        return res;
    }) 
}

// 订单列表
export const orderSearch = (data) => {
    return Fly.get('/papi/order/search', data).then((res)=>{
        return res;
    }) 
}

// 订单详情
export const getOrderDetail = (oid) => {
    return Fly.get(`/papi/order/${oid}`).then((res)=>{
        return res;
    }) 
}

// 订单继续支付
export const payOrderSign = (data) => {
    return Fly.get('/papi/payOrderSign', data).then((res)=>{
        return res;
    }) 
}

// 抽奖活动详情
export const splitLotteryActivity = (data) => {
    return Fly.get('/papi/splitLotteryActivity', data).then((res)=>{
        return res;
    }) 
}

// 抽奖记录
export const myCodes = (data) => {
    return Fly.get('/papi/lottery/myCodes', data).then((res)=>{
        return res;
    }) 
}

// 中奖商品查询
export const myPrize = (data) => {
    return Fly.get('/papi/lottery/myPrize', data).then((res)=>{
        return res;
    }) 
}

// 邀请记录
export const inviteRecord = (data) => {
    return Fly.get('/papi/user/invite-record', data).then((res)=>{
        return res;
    }) 
}

// 碎片合集
export const fragmentFolder = (data) => {
    return Fly.get('/papi/fragment/folder', data).then((res)=>{
        return res;
    })
}

// 碎片列表
export const fragmentMine = (data) => {
    return Fly.get('/papi/fragment/mine', data).then((res)=>{
        return res;
    })
}

// 碎片详情
export const fragmentInfo = (data) => {
    return Fly.get('/papi/fragment/info', data).then((res)=>{
        return res;
    })
}

// 碎片转赠
export const givenFragment = (data) => {
    return Fly.post('/papi/fragment/transfer', data).then((res)=>{
        return res;
    })
}

// 校验是否填写二级密码
export const checkTradePwd = (data) => {
    return Fly.get('/API/user/checkTradePwd', data).then((res)=>{
        return res;
    })
}

// 获取用户脱敏手机号
export const getMyPhone = (data) => {
    return Fly.get('/API/user/myPhone', data).then((res)=>{
        return res;
    })
}

// 获取设置二级密码验证码
export const sendTradeCode = (data) => {
    return Fly.get('/API/user/sendTradeCode', data).then((res)=>{
        return res;
    })
}

// 提交二级密码
export const setTradePwd = (data) => {
    return Fly.post('/API/user/setTradePwd', data).then((res)=>{
        return res;
    })
}

// pc 
// 更新商品
export const goodsApiEvent = (obj)=>{
    return Fly.post("/API/goods/edit",obj).then((res)=>{
        return res;
    })
}
// 获取商品列表
export const get_goods_list = (json)=>{
    return Fly.get("/API/goods/query",json).then((res)=>{
        return res;
    })
}
// 获取商品详情
export const get_goods_detail = (json) =>{
    return Fly.get("/API/goods/query-detail?goodsId="+json).then((res)=>{
        return res;
    })
}
// 删除某个商品
export const del_goods = (id)=>{
    return Fly.delete("/API/goods/delete",id).then((res)=>{
        return res;
    })
}
// Notice
// 更新公告
export const noticeApiEvent = (obj)=>{
    return Fly.post("/API/notice/edit",obj).then((res)=>{
        return res;
    })
}
// 获取公告列表
export const get_notice_list = (json)=>{
    return Fly.get("/API/notice/query",json).then((res)=>{
        return res;
    })
}
// 删除某个公告
export const del_notice = (id)=>{
    return Fly.delete("/API/notice/delete",id).then((res)=>{
        return res;
    })
}
// 给某个用户发送订单
export const orderApiEvent = (data)=>{
    return Fly.post("/papi/collection/airdrop",data).then((res)=>{
        return res;
    })
}
// 后台管理获取订单列表 GET /order/query
export const get_order_list = (data)=>{
    return Fly.post("/API/order/query",data).then((res)=>{
        return res;
    })
}
// 获取藏品
export const get_collection = (data)=>{
    return Fly.post("/papi/collection/mine", data).then((res)=>{
        return res;
    })
}
// 兑换
export const exchange = (data) => {
    return Fly.post("/papi/exchange/exchange", data).then((res) => {
        return res
    })
}

// pc 权限
export const UserConfig = () =>{
	return Fly.get('/papi/user/config').then((res)=>{
		return res
	})
}

// 藏品合集
export const collectionFolder = () =>{
    return Fly.get('/papi/collection/folder').then((res) => {
		return res
	})
}