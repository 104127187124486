import React from 'react';
import loadable from '@loadable/component';
import Loading from './loading.jsx';
const PRYOLoginView = loadable(() => import('../PRYO/phone/login/index.jsx'), {
	fallback: <Loading />
});
const PRYOResetPassword= loadable(() => import('../PRYO/phone/resetPassword/index.jsx'), {
	fallback: <Loading />
});
const PRYORegisteredView = loadable(() => import('../PRYO/phone/registered/index.jsx'), {
	fallback: <Loading />
});
const PRYOMineView = loadable(() => import('../PRYO/phone/mine/index.jsx'), {
	fallback: <Loading />
});
const PRYOHomeView = loadable(() => import('../PRYO/phone/home/index.jsx'), {
	fallback: <Loading />
});
const PRYOUserSetting = loadable(() => import('../PRYO/phone/userSetting/index.jsx'), {
	fallback: <Loading />
});
const PRYOUserSettingPas = loadable(() => import('../PRYO/phone/userSetting/password/index.jsx'), {
	fallback: <Loading />
});
const PRYOGoodsDetail = loadable(() => import('../PRYO/phone/goodsDetail/index.jsx'), {
	fallback: <Loading />
});
const PRYOCollectDetail = loadable(() => import('../PRYO/phone/collectDetail/index.jsx'), {
	fallback: <Loading />
});
const PRYOCollectCertificate = loadable(() => import('../PRYO/phone/collectCertificate/index.jsx'), {
	fallback: <Loading />
});
const PRYOMysteryBox = loadable(() => import('../PRYO/phone/mysteryBox/index.jsx'), {
	fallback: <Loading />
});
const PRYOAddress = loadable(() => import('../PRYO/phone/address/index.jsx'), {
	fallback: <Loading />
});
const PRYOSetting = loadable(() => import('../PRYO/phone/setting/index.jsx'), {
	fallback: <Loading />
});
const PRYOGiven = loadable(() => import('../PRYO/phone/given/index.jsx'), {
	fallback: <Loading />
});
const PRYOGivenRecord = loadable(() => import('../PRYO/phone/givenRecord/index.jsx'), {
	fallback: <Loading />
});
const PRYOWxAccount = loadable(() => import('../PRYO/phone/wxAccount/index.jsx'), {
	fallback: <Loading />
});
const PRYOSynthetic = loadable(() => import('../PRYO/phone/synthetic/index.jsx'), {
	fallback: <Loading />
});
const PRYOSyntheticRecord = loadable(() => import('../PRYO/phone/syntheticRecord/index.jsx'), {
	fallback: <Loading />
});
const PRYOSyntheticActivity = loadable(() => import('../PRYO/phone/syntheticActivity/index.jsx'), {
	fallback: <Loading />
});
const PRYOForgetPass = loadable(() => import('../PRYO/phone/userSetting/forgetPass/index.jsx'), {
	fallback: <Loading />
});
const PRYOOrder = loadable(() => import('../PRYO/phone/order/index.jsx'), {
	fallback: <Loading />
});
const PRYOOrderDetail = loadable(() => import('../PRYO/phone/order/detail.jsx'), {
	fallback: <Loading />
});
const PRYOLuckyDraw = loadable(() => import('../PRYO/phone/luckyDraw/index.jsx'), {
	fallback: <Loading />
});
const PRYOMyCodes = loadable(() => import('../PRYO/phone/luckyDraw/myCodes.jsx'), {
	fallback: <Loading />
});
const PRYOInviteUser = loadable(() => import('../PRYO/phone/inviteUser/index.jsx'), {
	fallback: <Loading />
});
const PRYOFragment = loadable(() => import('../PRYO/phone/fragment/index.jsx'), {
	fallback: <Loading />
});
const PRYOFragmentDetail = loadable(() => import('../PRYO/phone/fragment/detail.jsx'), {
	fallback: <Loading />
});
const PRYOGivenPwd = loadable(() => import('../PRYO/phone/setting/givenPwd/index.jsx'), {
	fallback: <Loading />
});
// PRYO ---- pc
const PRYOPCLogin = loadable(() => import('../PRYO/pc/login/index.jsx'), {
	fallback: <Loading />
});
const PRYOPCGoodsList = loadable(()=>import("../PRYO/pc/goods/index.jsx"),{
	fallback:<Loading />
})
const PRYOPCGoodsDetail = loadable(()=>import("../PRYO/pc/goods/detail/index"),{
	fallback: <Loading />
})
const PRYOPCNoticeList = loadable(()=>import("../PRYO/pc/notice/index.jsx"),{
	fallback:<Loading />
})
const PRYOPCNoticeDetail = loadable(()=>import("../PRYO/pc/notice/detail/index"),{
	fallback: <Loading />
})
const PRYOPCOrderList =loadable(()=>import("../PRYO/pc/order/index.jsx"),{
	fallback:<Loading />
})
const PRYOPCUserList=loadable(()=>import("../PRYO/pc/user/index.jsx"),{
	fallback:<Loading />
})
// AUX
const userDetail = loadable(() => import('../pages/User/detail.jsx'), {
	fallback: <Loading />
});
const wirteFrom = loadable(() => import('../pages/write/from.jsx'), {
	fallback: <Loading />
});
const changeOptions = loadable(() => import('../pages/write/options.jsx'), {
	fallback: <Loading />
});
const PersonList = loadable(() => import('../pages/PersonList/index.jsx'), {
	fallback: <Loading />
});
const TodoDetail = loadable(() => import('../pages/TodoDetail/index.jsx'), {
	fallback: <Loading />
});
const TodoList = loadable(() => import('../pages/TodoList/index.jsx'), {
	fallback: <Loading />
});
// 他人的用户详情
const PersonoDetail = loadable(()=>import("../pages/PersonDetail/index.jsx"),{
	fallback:<Loading />
})
// 某个人的某个选项的详情
const OptionDetail = loadable(()=>import("../pages/optionDetail/index.jsx"),{
	fallback:<Loading />
})

// 修改事件
const UpdateFrom = loadable(()=>import("../pages/write/updateEvent.jsx"),{
	fallback:<Loading />
})

// 先选择 选项再 记录事件
const EventAllready =  loadable(()=>import("../pages/PersonDetail/AllreadyOption.jsx"),{
	fallback:<Loading />
})
// list 
const ListPage = loadable(()=>import("../pages/list/index.jsx"),{
	fallback:<Loading />
})
// tips
const Love = loadable(()=>import("../PRYO/phone/love/index.jsx"),{
	fallback:<Loading />
})
const PRYOActivity = loadable(()=>import("../PRYO/phone/activity/index.jsx"),{
	fallback:<Loading />
})

const PRYOCollectList = loadable(()=>import("../PRYO/phone/collectList/list.jsx"),{
	fallback:<Loading />
})


const RouterArr = [
	// PRYO
	{
		path: '/phone/mine',
		name: 'PRYO-login',
		component: PRYOMineView,
		meta: {
			token: false,
			title: '我的',
			bodyStyle:{
				backgroundColor: "#1D1F23"
			}
		}
	},
	// PRYOGoodsDetail
	{
		path:"/phone/goodsDetail",
		name:"PRYO-PRYOGoodsDetail",
		component:PRYOGoodsDetail,
		meta: {
			token: true,
			title: '商品详情',
			root:"/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			}
		}
	},
	{
		path: '/phone/home',
		name: 'PRYO-home',
		component: PRYOHomeView,
		meta: {
			token: false,
			title: '至臻数藏',
			bodyStyle:{
				backgroundColor: "#1D1F23"
			}
		}
	},
	{
		path: '/phone/goodsDetail',
		name: 'PRYO-goodsDetail',
		component: PRYOGoodsDetail,
		meta: {
			token: false,
			title: '商品详情'
		}
	},
	{
		path:"/phone/login",
		name:"PRYO-login",
		component:PRYOLoginView,
		meta:{
			token:false,
			title:"登录您的账户",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			}
		}
	},
	// PRYOResetPassword
	{
		path:"/phone/resetPassword",
		name:"PRYO-resetPassword",
		component:PRYOResetPassword,
		meta:{
			token:false,
			title:"重置密码",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			}
		}
	},
	// PRYORegisteredView
	{
		path:"/phone/registered",
		name:"PRYO-registered",
		component:PRYORegisteredView,
		meta:{
			token:false,
			title:"注册",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			}
		}
	},
	// 用户设置 PRYOUserSetting
	{
		path:"/phone/setting",
		name:"PRYO-setting",
		component:PRYOUserSetting,
		meta:{
			token:true,
			title:"用户设置",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
			root:"/phone/mine"
		}
	},
	// PRYOUserSettingPas
	{
		path:"/phone/settingPas",
		name:"PRYO-settingPas",
		component:PRYOUserSettingPas,
		meta:{
			token:true,
			title:"用户密码设置",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
			root:"/phone/mine"
		}
	},
	{
		path: "/phone/activity",
		name: "PRYO-activity",
		component:PRYOActivity,
		meta:{
			token: false,
			title: '至臻数藏'
		}
	},
	{
		path: "/phone/collectDetail/:user_collection_id",
		name: 'PRYO-collectDetail',
		component: PRYOCollectDetail,
		meta:{
			token: true,
			title: '藏品详情',
			root:"/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: "/phone/collectCertificate/:user_collection_id",
		name: "PRYO-collectCertificate",
		component: PRYOCollectCertificate,
		meta: {
			token: true,
			title: '藏品证书',
			root: "/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: "/phone/mysterybox",
		name: "PRYO-mysterybox",
		component: PRYOMysteryBox,
		meta: {
			token: true,
			title: '我的盲盒',
			root: "/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: "/phone/address",
		name: "PRYO-address",
		component: PRYOAddress,
		meta: {
			token: true,
			title: '收货地址',
			root: "/phone/home",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: "/phone/userSetting",
		name: "PRYO-userSetting",
		component: PRYOSetting,
		meta: {
			token: true,
			title: '设置',
			root: "/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: "/phone/given/:user_collection_id",
		name: "PRYO-given",
		component: PRYOGiven,
		meta: {
			token: true,
			title: '转赠',
			root: "/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: "/phone/givenRecord",
		name: "PRYO-givenRecord",
		component: PRYOGivenRecord,
		meta: {
			token: true,
			title: '转赠记录',
			root: "/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: "/phone/synthetic/:type",
		name: "PRYO-synthetic",
		component: PRYOSynthetic,
		meta: {
			token: true,
			title: '合成/兑换列表',
			root: "/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: "/phone/syntheticRecord",
		name: "PRYO-syntheticRecord",
		component: PRYOSyntheticRecord,
		meta: {
			token: true,
			title: '合成记录',
			root: "/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: "/phone/syntheticActivity/:activity_id",
		name: "PRYO-syntheticActivity",
		component: PRYOSyntheticActivity,
		meta: {
			token: true,
			title: '合成活动',
			root: "/phone/mine",
		}
	},
	{
		path: "/phone/wxSpringBoard",
		name: "PRYO-wxSpringBoard",
		component: PRYOWxAccount,
		meta: {
			title: '识别关注，继续参与活动',
		}
	},
	{
		path: '/phone/forgetPass',
		name: 'PRYO-forgetPass',
		component: PRYOForgetPass,
		meta: {
			title: '忘记密码',
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: '/phone/order',
		name: 'PRYO-order',
		component: PRYOOrder,
		meta: {
			title: '我的订单',
			root: "/phone/mine",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: '/phone/orderDetail/:oid',
		name: 'PRYO-orderDetail',
		component: PRYOOrderDetail,
		meta: {
			title: '订单详情',
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: '/phone/luckyDraw',
		name: 'PRYO-luckyDraw',
		component: PRYOLuckyDraw,
		meta: {
			title: '活动详情',
			token: true,
			root: '/phone/home',
			bodyStyle: {
				backgroundColor: '#F5F5F5'
			}
		}
	},
	{
		path: '/phone/mycodes',
		name: 'PRYO-mycodes',
		component: PRYOMyCodes,
		meta: {
			title: '幸运摇号',
			token: true,
			root: '/phone/home',
			bodyStyle: {
				backgroundColor: '#1D1F23'
			}
		}
	},
	{
		path: '/phone/collectList/:goods_id',
		name: 'PRYO-CollectList',
		component: PRYOCollectList,
		meta: {
			title: '藏品列表',
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: '/phone/inviteUser',
		name: 'PRYO-inviteUser',
		component: PRYOInviteUser,
		meta: {
			title: '邀请好友',
			token: true,
			root: '/phone/mine'
		}
	},
	{
		path: '/phone/fragmentList/:goods_id',
		name: 'PRYO-fragmentList',
		component: PRYOFragment,
		meta: {
			title: '我的碎片',
			token: true,
			root: '/phone/mine',
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: '/phone/fragmentDetail/:user_fragment_id',
		name: 'PRYO-fragmentDetail',
		component: PRYOFragmentDetail,
		meta: {
			title: '碎片详情',
			token: true,
			root: '/phone/mine',
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	{
		path: '/phone/givenPwd',
		name: 'PRYO-givenPwd',
		component: PRYOGivenPwd,
		meta: {
			title: '二级密码',
			token: true,
			root: '/phone/mine',
			bodyStyle:{
				backgroundColor: "#1D1F23"
			},
		}
	},
	// PRYO --- pc
	{
		path:"/pc/login",
		name:"PRYO-pc-login",
		component:PRYOPCLogin,
		meta:{
			token:false,
			title:"登录",
			bodyStyle:{
				backgroundColor: "#1D1F23"
			}
		}
	},
	{
		path:"/pc/goodsList",
		name:"PRYO-pc-goodsList",
		component:PRYOPCGoodsList,
		meta:{
			token:true,
			root:"/pc/login",
			title:"商品列表",
			component:true
		}
	},
	
	{
		path:"/pc/goodsDetail",
		name:"PRYO-pc-goodsDetail",
		component:PRYOPCGoodsDetail,
		meta:{
			token:true,
			root:"/pc/login",
			title:"商品详情",
			component:true
		}
	},
	// PRYOPCNoticeList
	{
		path:"/pc/noticeList",
		name:"PRYO-pc-noticeList",
		component:PRYOPCNoticeList,
		meta:{
			token:true,
			root:"/pc/login",
			title:"公告列表",
			component:true
		}
	},
	{
		path:"/pc/noticeDetail",
		name:"PRYO-pc-noticeDetail",
		component:PRYOPCNoticeDetail,
		meta:{
			token:true,
			root:"/pc/login",
			title:"公告详情",
			component:true
		}
	},
	{
		path:"/pc/orderList",
		name:"PRYO-pc-orderList",
		component:PRYOPCOrderList,
		meta:{
			token:true,
			root:"/pc/login",
			title:"订单列表",
			component:true
		}
	},
	// PRYOPCUserList
	{
		path:"/pc/userList",
		name:"PRYO-pc-userList",
		component:PRYOPCUserList,
		meta:{
			token:true,
			root:"/pc/login",
			title:"用户列表",
			component:true
		}
	},
	// AUX
	{
		path: '/userDetail',
		name: 'userDetail',
		component: userDetail,
		meta: {
			token: true,
			title: '个人详情'
		}
	},
	{
		path:"/ListPage",
		name:"ListPage",
		component:ListPage,
		meta:{
			token:true,
			title:"育人发动机"
		}
	},
	{
		path:"/PersonoDetial",
		name:"PersonoDetial",
		component:PersonoDetail,
		meta:{
			token:false,
			title:"徒弟详情"
		}
	},
	{
		path:"/optionDetail",
		name:"OptionDetail",
		component:OptionDetail,
		meta:{
			token:true,
			title:"选项详情"
		}
	},
	{
		path:"/EventAllready",
		name:"EventAllready",
		component:EventAllready,
		meta:{
			token:true,
			title:"选项事件录入"
		}
	},
	{
		path: '/personList',
		name: 'personList',
		component: PersonList,
		meta: {
			token: true,
			title: '人员列表'
		}
	},
	{
		path: '/write',
		name: 'wirteFrom',
		component: wirteFrom,
		meta: {
			token: true,
			title: '写事件'
		},
		children: [
			{
				path: 'options',
				name: 'changeOptions',
				component: changeOptions,
				meta: {
					token: true,
					title: '选项录入'
				}
			}
		]
	},
	{
		path:"/updateEvent",
		name:"UpdateFrom",
		component:UpdateFrom,
		meta:{
			token:true,
			title:"更新事件"
		}
	},
	{
		path: '/TodoDetail',
		name: 'TodoDetail',
		component: TodoDetail,
		meta: {
			token: true,
			title: '事件详情'
		}
	},
	{
		path: '/TodoList',
		name: 'TodoList',
		component: TodoList,
		meta: {
			token: true,
			title: '待办列表'
		}
	},
	{
		path:"/love",
		name:"love",
		component:Love,
		meta:{
			token:false,
			title:"MY Love"
		}
	}
];
export default RouterArr;
