import React, { useState,useEffect } from "react";
import { Menu, Button, notification } from "antd";
import { PicCenterOutlined, LogoutOutlined } from "@ant-design/icons";

import store from "../../store/index";
import IndexStyle from "./menu.module.scss";
import { UserConfig } from "../../fly/pryoApis";
function Pc(props) {
  const defaultSelectedKeys = props.activeKey;
  const { SubMenu } = Menu;
   const [MenuData, setMenuData] = useState(null)
  const goRouter = (obj) => {
    let sameObj = MenuData.find((item, index) => {
      return item.key === obj.key;
    });
    props.history.push(sameObj.router);
  };
  const loginOut = () => {
    if (window.sessionStorage.getItem("PC-YOYOTOKEN")) {
      window.sessionStorage.removeItem("PC-YOYOTOKEN");
      window.sessionStorage.removeItem("user");
      window.sessionStorage.removeItem('Permissions');
      window.localStorage.removeItem('PC-YOYOTOKEN');
      window.localStorage.removeItem('user');
      notification["success"]({
        message: "成功",
        description: "成功退出账号",
        duration:2,
        onClose: () => {
          props.history.replace("/pc/login");
        },
      });
    }
  };
  const PermissionsInterface = async () => {
    try {
        const res = await UserConfig();
        const { code, config } = res;
        if (code === 0) {
          if(config){
            window.sessionStorage.setItem('Permissions',JSON.stringify(config));
            let MenuData = store.getState().menu.filter((val) => val.key == '1' || val.key == '3');
            setMenuData(MenuData)
          }else{
            setMenuData(store.getState().menu)
          }
        }else{
            notification['error']({
                message: res.message,
                description:
                    res.message,
            });
        }
    } catch(e) {
      console.log(e)
    }
  
  }
   useEffect(() =>{
    PermissionsInterface()
  },[])
  return (
    <div className={`${[IndexStyle.mainView].join(" ")}`}>
      <div className={`${[IndexStyle.MenuView].join(" ")}`}>
        <h1>PRYO</h1>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[defaultSelectedKeys]}
        >
          {MenuData && MenuData.map((item, index) => {
            return (
              <Menu.Item key={item.key} icon={item.icon} onClick={goRouter}>
                <div
                  className={`${[IndexStyle.LiView, IndexStyle.flex].join(
                    " "
                  )}`}
                >
                  <p>{item.title}</p>
                  {/* <p><PicCenterOutlined /></p> */}
                </div>
              </Menu.Item>
            );
          })}
          {/* <SubMenu key="sub1" title="子菜单">
          <Menu.Item key="5">子菜单项</Menu.Item>
        </SubMenu> */}
        </Menu>
        <div className={`${[IndexStyle.loginOutView].join(" ")}`}>
          <Button
            onClick={loginOut}
            type="primary"
            danger
            icon={<LogoutOutlined />}
          ></Button>
        </div>
      </div>
      <div className={`${[IndexStyle.rightView].join(" ")}`}>
        {props.children}
      </div>
    </div>
  );
}

export default Pc;
