export const CHANGE_LOADING = 'changeLoading';

export const CHANGE_USER = 'changeuser';
// 改变系统主题
export const CHANGE_THEME = 'changeTheme';
// 改变从人员列表选择的人员
export const CHANGE_PERSONLIST = 'changePersonlist'
// 保存 更新事件录入信息
export const CHANGE_EVENTMESS = 'changeEventMess';
// 更新 当前选择中的选项
export const CHANGE_SELECT_OPTIONS = 'changeSelectOptions';